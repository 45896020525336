import React from "react";
import IMAGES from "../images";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className=" bg-[#1C1C1C] flex p-4 lg:pl-20  lg:py-16 flex-col lg:flex-row md:pt-8 md:px-20 lg:gap-8 xl:gap-20 2xl:justify-center 2xl:px-60">
      <div className="lg:w-1/2 max-w-[640px]">
        <div className="flex-1 xl:pr-12">
          <img src={IMAGES.Logo} alt="logo"  />
          <p className="text-xs text-white sm:text-sm mt-7">
            Jas International, a Qatar based company with logistics experts
            provides Shipping and Logistics solutions throughout the world.
            Jas International provides door to door delivery solutions with our
            in-house warehousing and distribution facilities.
          </p>
        </div>

        <div className="flex items-center gap-4 my-8">
          <a href="https://www.facebook.com/" target="_blank">
            <img src={IMAGES.FacebookIcon} alt="facebook icon" />
          </a>
          <a href="https://www.twitter.com" target="_blank">
            <img src={IMAGES.TwitterIcon} alt="twitter icon" />
          </a>
          <a href="https://www.linkedin.com" target="_blank">
            <img src={IMAGES.LinkedInIcon} alt="linkedin icon" />
          </a>
          <a href="https://www.instagram.com" target="_blank">
            <img src={IMAGES.InstagramIcon} alt="insta icon" />
          </a>
        </div>
      </div>

      <div className="flex flex-row gap-2 mb-4 md:gap-28">
        <div className="min-w-fit">
          <h4 className="mb-4 text-lg font-medium text-white lg:font-bold lg:mb-8">
            QUICK LINKS
          </h4>
          <div className="">
            <Link to="/">
              <p className="text-xs sm:text-sm text-[#DDDDDD]">Home</p>
            </Link>
            <Link to="/about">
              <p className="text-xs sm:text-sm text-[#DDDDDD] py-4">About us</p>
            </Link>
            <Link to="/services">
              <p className="text-xs sm:text-sm text-[#DDDDDD]">Service</p>
            </Link>
            <Link to="/about">
              <p className="text-xs sm:text-sm text-[#DDDDDD] py-4">
                Contact us
              </p>
            </Link>
          </div>
        </div>

        <div className="">
          <h4 className="mb-4 text-lg font-medium text-white lg:font-bold lg:mb-8">
            QUICK CONNECT
          </h4>
          <a href={`tel:${"(+974) 40386434"}`}>
            <div className="flex items-center gap-2 ">
              <img src={IMAGES.MobileIcon} alt="mobile icon" />
              <span className="text-xs sm:text-sm text-[#DDDDDD]">
              (+974) 40386434
              </span>
            </div>
          </a>

          <a href={`mailto:${"info@jasintls.com"}`}>
            <div className="flex items-center gap-2 py-4">
              <img src={IMAGES.EmailIcon} alt="email icon" />
              <p className="text-xs sm:text-sm text-[#DDDDDD]">
              info@jasintls.com
              </p>
            </div>
          </a>

          <a href="https://www.google.com/maps/search/Wakra+Boulevard,+Office+No-+133,+Abdul+Rahman+Bin+Jassim+Street,+P.O+Box:+63434,+++Al+Wakrah,+State+Of+Qatar/@25.1680729,51.2824371,9.71z?entry=ttu">
            <div className="flex items-start gap-2 ">
              <img
                src={IMAGES.LocationIcon}
                alt="location icon"
                className="ml-1"
              />
              <span className="text-xs sm:text-sm text-[#DDDDDD] ">
                
                P.O Box: 4381, <br /> Doha State of Qatar.
              </span>
            </div>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
