import Footer from "../components/Footer";
import Header from "../components/Header";
import IMAGES from "../images";

function Services() {
  return (
    <>
      <Header />
      <section className="flex items-center justify-center mt-16 text-3xl font-bold text-white md:text-4xl lg:text-5xl lg:mt-0 bgCotainerImage">
        Services
      </section>
      <section className="w-full">
        <div className="max-w-[1440px] mx-auto mb-20 lg:mt-20">
          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="flex flex-col justify-center mx-8 my-4 lg:w-1/2 lg:mx-0 lg:my-0 ">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl mr-8">
                <h3>Groupage Services</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="mr-8 leading-6 text-grayC-100 xl:leading-30 lg:mr-0">
                As a commitment to providing service efficiency, Scorpion
                Logistics has endeavoured to establish fixed date stuffing for
                worldwide distribution, in consonance with the shipping
                schedules of premium carriers. Our operations are smoothened out
                with the help of our Associates who are strategically located
                across the world. They are essentially handpicked professional
                organisations linked up to form a highly response-led and
                effective Jas International global network.
              </p>
            </div>
            <div className="lg:w-1/2 max-w-fit md:mx-auto">
              <img src={IMAGES.serviceTwo} />
            </div>
          </div>
          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="order-2 lg:w-1/2 max-w-fit lg:-order-1 md:mx-auto">
              <img src={IMAGES.serviceOne} />
            </div>
            <div className="flex flex-col justify-center my-4 mr-8 lg:w-1/2 lg:m-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl ml-8">
                <h3>Door to Door Logistics</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="ml-8 leading-6 text-grayC-100">
                Jas International specialises in offering services that span
                Shipping Documentation, Customs Clearance, Cargo Insurance,
                Warehousing and Local Distribution as a part of its Door-to-Door
                Logistics Service. That's the Convenience of a Single Window
                Advantage. In essence, you can hand over the documents to us and
                we will deliver the cargo at your doorstep, almost anywhere in
                the world.
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="flex flex-col justify-center mx-8 my-4 lg:w-1/2 lg:m-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl mr-8">
                <h3>Chemical / Liquid Bulk Logistics</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="mr-8 leading-6 text-grayC-100 xl:leading-30">
                Jas International provides intermodal supply chain management
                and unrivalled door-to-door logistical services to clients who
                require to move, store and discharge bulk loads of liquid
                product commodities such as hazardous chemicals, refrigerated
                foodstuffs and other liquid bulk logistics. Be it in tank
                containers, flexitanks, IBC’s, drums or any other means of
                transport, we provide our customers with the most economical
                solution to their transport needs.
              </p>
            </div>
            <div className="lg:w-1/2 max-w-fit md:mx-auto">
              <img src={IMAGES.serviceFour} />
            </div>
          </div>
          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="order-2 lg:w-1/2 max-w-fit lg:-order-1 md:mx-auto">
              <img src={IMAGES.serviceThree} />
            </div>
            <div className="flex flex-col justify-center my-4 lg:my-0 lg:w-1/2">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl ml-8">
                <h3>Buyer Consolidation</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="ml-8 text-grayC-100 xl:leading-30">
                Buyer Consolidation is our core activity and capability. We can
                receive cargo from multi-vendors at any of our warehouses and
                custom load containers to suit your requirements. This would
                translate into more speedy delivery at destination and the
                container can be unloaded at the stores directly. We are
                continuously striving to provide logistics solutions to our
                customers which gives them the leading edge information on their
                products.
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="flex flex-col justify-center mx-8 my-4 lg:w-1/2 lg:m-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl mr-8">
                <h3>Customs Clearance</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="mr-8 leading-6 text-grayC-100 xl:leading-30">
                Qatar is very strict when it comes to the documentation part for
                Customs clearance. A well designed and well-managed customs
                clearance program reduces cycle times and lowers overall cost.
                Our 10 years plus experienced staff understands the Customs
                requirements that help to clear shipments smoothly. Our experts
                can guide the customers on the preparation of documents as
                required by Customs.
              </p>
            </div>
            <div className="lg:w-1/2 max-w-fit md:mx-auto">
              <img src={IMAGES.serviceSix} />
            </div>
          </div>
          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="order-2 lg:w-1/2 max-w-fit lg:-order-1 md:mx-auto">
              <img src={IMAGES.serviceFive} />
            </div>
            <div className="flex flex-col justify-center my-4 mr-4 lg:w-1/2 lg:mr-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl ml-8">
                <h3>Transportation</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="ml-8 text-grayC-100 xl:leading-30">
                Close coordination is required when it comes to transportation
                services in Qatar. Our team works 24x7 to closely follow with
                the drivers, Customs agents and clients. We make sure that the
                shipments are cleared and delivered on time to the customs
                ensuring no additional cost to the customers.
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="flex flex-col justify-center mx-8 my-4 lg:w-1/2 lg:m-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl mr-8">
                <h3>Packing & Removals</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="mr-8 leading-6 text-grayC-100 xl:leading-30">
                Jas International is equipped with skilled persons in
                Industrial packing, handling personal effects and professionals
                in dismantling and moving heavy equipment.
              </p>
            </div>
            <div className="lg:w-1/2 max-w-fit md:mx-auto">
              <img src={IMAGES.serviceFourteen} />
            </div>
          </div>
          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="order-2 lg:w-1/2 max-w-fit lg:-order-1 md:mx-auto">
              <img src={IMAGES.serviceTewelve} />
            </div>
            <div className="flex flex-col justify-center my-4 mr-4 lg:w-1/2 lg:mr-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl ml-8">
                <h3>Lashing & Securing Heavy Equipment</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="ml-8 text-grayC-100 xl:leading-30">
                Jas International delivers the right solutions and right
                service to its valued customers at the right time and right
                price. Stuffing and lashing of the cargoes are also carried out
                professionally by ensuring the quality of materials used for
                lashing the cargoes.
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="flex flex-col justify-center my-4 ml-8 lg:w-1/2 lg:m-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl mr-8">
                <h3>Warehousing & Distributions</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="mr-8 leading-6 text-grayC-100 xl:leading-30">
                Jas International handles general and cold storage warehousing
                facilities and manages storage and distribution services for
                leading companies in Qatar. We have customers who are
                international branded companies and some freight forwarders as
                well.
              </p>
            </div>
            <div className="lg:w-1/2 max-w-fit md:mx-auto">
              <img src={IMAGES.serviceThirteen} />
            </div>
          </div>
          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="order-2 lg:w-1/2 max-w-fit lg:-order-1 md:mx-auto lg:mr-0">
              <img src={IMAGES.serviceEleven} />
            </div>
            <div className="flex flex-col justify-center my-4 mr-4 lg:w-1/2 lg:m-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl ml-8">
                <h3>Documents Consultancy</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="ml-8 text-grayC-100 xl:leading-30">
                We understand the Qatar Customs/port rules and regulations and
                their procedure. We thus can guide on the documents required for
                customs clearance, approvals required from respective
                ministries, chambers etc. We can guide on required approval
                prior to the shipment arrivals.
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="flex flex-col justify-center my-4 ml-8 lg:w-1/2 lg:m-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl mr-8">
                <h3>Project Handling</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="mr-8 leading-6 text-grayC-100 xl:leading-30">
                Cargoes come in all shapes and sizes and cannot always be
                squeezed into a standard container. From military tanks to
                road-building machinery, oil drilling to specialist garments,
                Jas International has the experience and know-how to safely
                transport freight that cannot be containerized. We have Out of
                Gauge (OOG) and special cargo handling facilities and equipment
                available globally
              </p>
            </div>
            <div className="lg:w-1/2 max-w-fit md:mx-auto">
              <img src={IMAGES.serviceTen} />
            </div>
          </div>
          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="order-2 lg:w-1/2 max-w-fit lg:-order-1 md:mx-auto">
              <img src={IMAGES.serviceNine} />
            </div>
            <div className="flex flex-col justify-center my-4 mr-4 lg:w-1/2 lg:mr-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl ml-8">
                <h3>Events & Exhibitions</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="ml-8 text-grayC-100 xl:leading-30">
                Exhibitions and special events require detailed planning due to
                their complex logistical requirements. Jas International
                provides a complete scope of event logistics and transportation
                services through our skilled team of exhibition experts. Our
                professional and reliable partners are available around the
                clock, 365 days per year, to deliver.
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="flex flex-col justify-center mx-8 my-4 lg:w-1/2 lg:m-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl mr-8">
                <h3>Trading / Sourcing</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="mr-8 leading-6 text-grayC-100 xl:leading-30">
                Our parent company is in business since 2009 with a trading
                license and can import all products for our clients. We source
                materials required for our customers, arrange freight, Customs
                clearance, transport and warehousing under a single-window i.e
                Procurement and logistics.
              </p>
            </div>
            <div className="lg:w-1/2 max-w-fit md:mx-auto">
              <img src={IMAGES.serviceEight} />
            </div>
          </div>
          <div className="flex flex-col justify-between lg:mx-4 xl:mx-28 lg:flex-row">
            <div className="order-2 lg:w-1/2 max-w-fit lg:-order-1 md:mx-auto">
              <img src={IMAGES.serviceSeven} />
            </div>
            <div className="flex flex-col justify-center my-4 mr-4 lg:w-1/2 lg:mr-0">
              <div className="font-medium text-2xl lg:font-semibold max-w-fit xl:leading-[45px] lg:text-2xl xl:text-3xl ml-8">
                <h3>Aircraft Chartering</h3>
                <div className="border-b-2 my-2 border-[#FAAA51] border-solid w-[90%]"></div>
              </div>
              <p className="ml-8 text-grayC-100 xl:leading-30">
                Our Strategic Relations with several Carriers, Operators &
                broking Companies allows us to reach the entire world, whatever
                be the required origin or destination. This platform offers
                unparalleled access to the world's key trade lanes, connecting
                global markets seamlessly for Specialised & Critical loads for
                all JUST IN TIME Deliveries.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Services;
